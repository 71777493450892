<template>
  <div class="d-flex justify-content-center mt-10">
    <div class="container">
      <!--begin::Row-->
      <div class="row d-flex justify-content-center">
        <v-card tile></v-card>
        <div class="card card-custom card-transparent">
          <div class="card card-custom card-shadowless rounded-top-0">
            <v-card :loading="isLoggingLoading">
              <BoxNewPassword
              :token="$route.params.token"
              ></BoxNewPassword>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BoxNewPassword from "@/view/commons/BoxNewPassword";
import Vue from "vue";
import {VueReCaptcha} from "vue-recaptcha-v3";
import {
  FORGOT_PASSWORD_CHECK_TOKEN,
} from "@/core/services/store/auth.module";


Vue.use(VueReCaptcha, {siteKey: "6LdZLo4UAAAAAJ78awvAYLVjskijxdJ0A-sMOkWe"});

export default {
  data() {
    return {
      token: String
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "isLoggingLoading",
      "getForgotPasswordCheckTokenRequestStatus"
    ]),
  },
  components: {
    BoxNewPassword
  },
  mounted() {
    if(this.$route.params.token.length > 20){
      this.$store.dispatch(FORGOT_PASSWORD_CHECK_TOKEN, this.$route.params.token);
    }
  },
  watch: {
    isAuthenticated() {

    }
  }
}
</script>