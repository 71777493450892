<template>
  <div class="d-flex flex-column flex-root">
    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
        id="kt_new_password"
    >
      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
            <div class="form-group">
              <!--begin::NewPassword-->
              <div class="login-form login-signin">
                <v-form
                    ref="kt_change_password_form"
                    v-model="valid"
                    lazy-validation
                >
                  <div class="pb-13 pt-lg-0 pt-5">
                    <h3
                        class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                    >
                      {{ $t("AUTH.NEW_PASSWORD.TITLE") }}
                    </h3>
                  </div>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                          name="password"
                          ref="password"
                          v-model="password"
                          color="blue-grey lighten-2"
                          :label="$t('FORM.PASSWORD')"
                          dense
                          filled
                          :rules="requiredPasswordRules"
                          @click:append="show1 = !show1"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show1 ? 'text' : 'password'"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                          name="repassword"
                          ref="repassword"
                          v-model="repassword"
                          color="blue-grey lighten-2"
                          :label="$t('FORM.CONFIRM_PASSWORD')"
                          dense
                          filled
                          :rules="requiredPasswordRules"
                          @click:append="show2 = !show2"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show2 ? 'text' : 'password'"
                          :error="passwordError"
                          hint="At least 8 characters"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="pb-lg-0 pb-5">
                        <button
                            ref="kt_login_signin_submit"
                            class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                            @click="recaptcha"
                        >
                          {{ $t("AUTH.NEW_PASSWORD.BUTTON") }}
                        </button>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
              <!--end::NewPassword-->
            </div>

      </div>
    </div>
  </div>
</template>
<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>
<script>
import Vue from "vue";
import {rulesMixin} from "@/core/mixin/rules.mixin";
import {VueReCaptcha} from "vue-recaptcha-v3";
import {mapGetters} from "vuex";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import KTUtil from "@/assets/js/components/util";
import {FORGOT_PASSWORD_UPDATE_PASSWORD} from "@/core/services/store/auth.module";


Vue.use(VueReCaptcha, {siteKey: "6LdZLo4UAAAAAJ78awvAYLVjskijxdJ0A-sMOkWe"});
export default {
  name: "BoxNewPassword",
  data() {
    return {
      password: "",
      repassword: "",
      error: false,
      loaderEnabled: true,
      show1: false,
      show2: false,
      passwordError: false,
    }
  },
  mixins: [rulesMixin],
  computed: {
    ...mapGetters(["isLoggingLoading", "isAuthenticated", "layoutConfig"]),
    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    }
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
          KTUtil.getById(form_name),
          "animate__animated animate__backInUp"
      );
    },
    recaptcha(event) {
      event.preventDefault()
     if(this.password != this.repassword){
       this.passwordError = true;
     } else {
       this.passwordError = false;
       var context = this;
       if (this.$refs.kt_change_password_form.validate()) {
         this.$recaptchaLoaded().then(() => {
           this.$recaptcha("password_change_token").then((captcha) => {
             let payload = {}
             payload.captcha = captcha
             payload.token = this.token
             payload.password = this.password
             // clear existing errors
             context.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
             context.error = true;
             context.$store.dispatch(FORGOT_PASSWORD_UPDATE_PASSWORD, payload).then(function () {
               context.error = false;
               context.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
               context.$router.push({name: "login_page"});
             }).catch(() => {
               context.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
               });
           });
         });
       }
     }
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({name: "list_event_page"});
    }
  },
  props: {
    token: String
  },
  watch: {
    // isAuthenticated() {
    //   this.$router.push({ name: "list_event_page" });
    // }
  },
  components: {}
}

</script>